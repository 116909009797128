/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as RejectedIcon,
} from "@epam/assets/icons/common/navigation-close_bold-18.svg";
import {
	ReactComponent as ApprovedIcon,
} from "@epam/assets/icons/common/notification-done_bold-18.svg";
import {
	ReactComponent as DefaultIcon,
} from "@epam/assets/icons/common/radio-point-10.svg";
import {
	type FC,
	type SVGProps,
} from "react";

import {
	PresenceStatus,
} from "models/presences/constants";

interface GetPresenceStatusIconParams<IsUndefinedByDefault extends boolean> {
	presenceStatus: PresenceStatus | undefined;
	isUndefinedByDefault: IsUndefinedByDefault;
}

type Icon = FC<SVGProps<SVGSVGElement>>;

type GetPresenceStatusIconResult<IsUndefinedByDefault extends boolean> =
	IsUndefinedByDefault extends true
		? Icon | undefined
		: Icon;

const getPresenceStatusIcon = <IsUndefinedByDefault extends boolean>({
	presenceStatus,
	isUndefinedByDefault,
}: GetPresenceStatusIconParams<IsUndefinedByDefault>): GetPresenceStatusIconResult<
	IsUndefinedByDefault
> => {
	switch (presenceStatus) {
		case PresenceStatus.APPROVED: {
			return ApprovedIcon;
		}

		case PresenceStatus.REJECTED: {
			return RejectedIcon;
		}

		default: {
			if (isUndefinedByDefault) {
				// @ts-expect-error The type is correct.
				return undefined;
			}

			return DefaultIcon;
		}
	}
};

export {
	getPresenceStatusIcon,
};
