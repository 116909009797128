/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	OVERTIME_BASE_URL,
} from "pages/constants/base-routes";

import {
	generatePeriodParamsString,
} from "./generate-period-params-string";

type GenerateOvertimeDashboardPageUrlParams = WithPeriodDates;

const generateOvertimeDashboardPageUrl = ({
	periodStartDate,
	periodEndDate,
}: GenerateOvertimeDashboardPageUrlParams): string => {
	const periodParamsString = generatePeriodParamsString({
		periodStartDate,
		periodEndDate,
	});

	return `${OVERTIME_BASE_URL}/${periodParamsString}`;
};

export {
	generateOvertimeDashboardPageUrl,
};
