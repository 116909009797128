/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	getProjectsCommonFilterValuesFromLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	type DateString,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";

interface GenerateProjectPageParamsStringParams {
	baseUrl: string;
	employeeIds: EmployeeIds;
	fromDate: DateString;
	toDate: DateString;
	withFilter: boolean;
}

const generateExcelReportPage = ({
	baseUrl,
	employeeIds,
	fromDate,
	toDate,
	withFilter,
}: GenerateProjectPageParamsStringParams): string => {
	const pageParams = new URLSearchParams();

	const {
		showWithNpa,
		showWithVacation,
		showWithExcludedHours,
	} = getProjectsCommonFilterValuesFromLocalStorage();

	if (employeeIds.length > 0) {
		employeeIds.forEach((empId) => {
			pageParams.append("employeeUid", empId);
		});
	}

	pageParams.append("from", fromDate);

	pageParams.append("to", toDate);

	pageParams.append("withFilter", String(withFilter));

	pageParams.append("withVacation", String(showWithVacation));

	pageParams.append("withNpa", String(showWithNpa));

	pageParams.append("withExcludedHours", String(showWithExcludedHours));

	const pageParamsString = pageParams.toString();

	return `${baseUrl}?${pageParamsString}`;
};

export {
	generateExcelReportPage,
};
