/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isEmpty from "lodash/isEmpty";

import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type WithProjectId,
} from "models/projects/types";
import {
	PACKAGES_BASE_URL,
} from "pages/constants/base-routes";

import {
	generatePeriodParamsString,
} from "./generate-period-params-string";

interface GeneratePackagesPageUrlParams extends WithProjectId,
	Partial<WithPeriodDates> {}

const generatePackagesPageUrl = ({
	projectId,
	periodStartDate,
	periodEndDate,
}: GeneratePackagesPageUrlParams): string => {
	const packagesBaseUrl = `${PACKAGES_BASE_URL}/${projectId}`;

	const pageParamsString = generatePeriodParamsString({
		periodStartDate,
		periodEndDate,
	});

	if (isEmpty(pageParamsString)) {
		return packagesBaseUrl;
	}

	return `${packagesBaseUrl}/${pageParamsString}`;
};

export {
	generatePackagesPageUrl,
};
