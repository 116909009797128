/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";

import {
	type PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	DEFAULT_PROJECT_DETAILS_VIEW_MODE,
	PROJECT_DETAILS_VIEW_LOCAL_STORAGE_KEY,
} from "pages/constants/project-page";

const getProjectDetailsViewFromLocalStorage = (): PROJECT_VIEW_MODE => {
	try {
		const projectDetailsView = localStorage.getItem(PROJECT_DETAILS_VIEW_LOCAL_STORAGE_KEY);

		if (!isNull(projectDetailsView)) {
			return projectDetailsView as PROJECT_VIEW_MODE;
		}
	} catch {}

	return DEFAULT_PROJECT_DETAILS_VIEW_MODE;
};

export {
	getProjectDetailsViewFromLocalStorage,
};
