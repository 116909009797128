/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	EMPLOYEES_BASE_URL,
} from "pages/constants/base-routes";
import {
	type EmployeesDashboardView,
} from "pages/constants/employees-page";
import {
	queryParamsSerializerDefault,
} from "utils/api";

import {
	generatePeriodParams,
} from "./generate-period-params";

interface GenerateEmployeesPageUrlParams extends WithPeriodDates {
	view?:
		| EmployeesDashboardView.EMPLOYEES_DETAILS
		| `:view(${EmployeesDashboardView.EMPLOYEES_DETAILS})`;
}

const generateEmployeesPageUrl = ({
	periodStartDate,
	periodEndDate,
	view,
}: GenerateEmployeesPageUrlParams): string => {
	const paramsString = queryParamsSerializerDefault({
		queryParams: {
			...generatePeriodParams({
				periodStartDate,
				periodEndDate,
			}),
			view,
		},
	});

	return `${EMPLOYEES_BASE_URL}/${paramsString}`;
};

export {
	generateEmployeesPageUrl,
};
