/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	queryParamsSerializerDefault,
} from "utils/api";

import {
	generatePeriodParams,
} from "./generate-period-params";

interface GenerateProjectPageParamsStringParams extends
	WithPeriodDates {
	viewMode?:
		| PROJECT_VIEW_MODE
		| `:viewMode(${PROJECT_VIEW_MODE})`;
}

const generateProjectPageParamsString = ({
	periodStartDate,
	periodEndDate,
	viewMode,
}: GenerateProjectPageParamsStringParams): string => {
	return queryParamsSerializerDefault({
		queryParams: {
			...generatePeriodParams({
				periodStartDate,
				periodEndDate,
			}),
			view: viewMode,
		},
	});
};

export {
	generateProjectPageParamsString,
};
