/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";

import {
	EMPLOYEES_DASHBOARD_VIEW_DEFAULT,
	EMPLOYEES_DASHBOARD_VIEW_LOCAL_STORAGE_KEY,
	type EmployeesDashboardView,
} from "pages/constants/employees-page";

const getEmployeesDashboardViewFromLocalStorage = (): EmployeesDashboardView => {
	try {
		const employeesDashboardView = localStorage.getItem(EMPLOYEES_DASHBOARD_VIEW_LOCAL_STORAGE_KEY);

		if (!isNull(employeesDashboardView)) {
			return employeesDashboardView as EmployeesDashboardView;
		}
	} catch {}

	return EMPLOYEES_DASHBOARD_VIEW_DEFAULT;
};

export {
	getEmployeesDashboardViewFromLocalStorage,
};
