/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	queryParamsSerializerDefault,
} from "utils/api";

import {
	generatePeriodParams,
} from "./generate-period-params";

type GeneratePeriodParamsStringParams = Partial<WithPeriodDates>;

const generatePeriodParamsString = ({
	periodStartDate,
	periodEndDate,
}: GeneratePeriodParamsStringParams): string => {
	return queryParamsSerializerDefault({
		// @ts-expect-error "Index signature is missing in type" error is not harmful here.
		queryParams: generatePeriodParams({
			periodStartDate,
			periodEndDate,
		}),
	});
};

export {
	generatePeriodParamsString,
};
