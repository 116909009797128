/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	OvertimeStatus,
} from "models/overtime/constants";

const getUpdateOvertimeRequestSuccessfulNotificationMessage = (
	overtimeStatus: OvertimeStatus | undefined,
): string => {
	const message = "Overtime request has been successfully edited";

	switch (overtimeStatus) {
		case OvertimeStatus.APPROVED: {
			return `${message} and approved`;
		}

		case OvertimeStatus.REJECTED: {
			return `${message} and rejected`;
		}

		case OvertimeStatus.CANCELLED: {
			return `${message} and cancelled`;
		}

		default: {
			return message;
		}
	}
};

export {
	getUpdateOvertimeRequestSuccessfulNotificationMessage,
};
