/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";

type GeneratePeriodParamsParams = Partial<WithPeriodDates>;

interface PeriodParams {
	from: GeneratePeriodParamsParams["periodStartDate"];
	to: GeneratePeriodParamsParams["periodEndDate"];
}

const generatePeriodParams = ({
	periodStartDate,
	periodEndDate,
}: GeneratePeriodParamsParams): PeriodParams => {
	return {
		from: periodStartDate,
		to: periodEndDate,
	};
};

export {
	generatePeriodParams,
};
