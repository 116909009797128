/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

import {
	type ProjectPositionMarkup,
} from "models/project-position-markups/types";
import {
	type MarkupWithDuration,
} from "pages/types/common";

/*
	Initially, there was an idea of sorting the markups according to markup
	statuses configuration (by `order` field). But the problem is, the schema of
	these statuses can change over time, and the configuration contains
	information about the current schema only.

	Then it was decided to put the `order` to the markups' data, so that even if
	the schema changes, the order would stay the same for the existing
	data.

	But this approach also has a problem: the same status (with the same id) may
	have different orders in different schemas, so that when several markups from
	different schemas with the same id, but different order, are presented on a
	page, it is unclear, which order should be used for the status.

	Since there are no plans to implement schemas changes' tracking yet,
	the simplest solution was sorting the statuses by id, since they are unique.
*/
const getSortedMarkups = <
	MarkupType extends ProjectPositionMarkup | MarkupWithDuration,
>(markups: MarkupType[]): MarkupType[] => {
	if (isEmpty(markups)) {
		return markups;
	}

	// The condition above ensures that at this point there is at least one markup.
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const sampleMarkup = markups.at(0)!;
	const sortingField = (
		/*
			Different endpoints provide different interfaces for markups
			(for example, on Project Summary and Project Details Position Detailed
			view pages), and we need to support them all.
			When the API is refined on BE side, this condition won't be necessary
			and should be refactored.
		*/
		"statusId" in sampleMarkup
			? "statusId"
			: "id"
	);

	return sortBy(
		markups,
		sortingField,
	);
};

export {
	getSortedMarkups,
};
