/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isEmpty from "lodash/isEmpty";

import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeId,
} from "models/employees/types";
import {
	TIME_JOURNAL_BASE_URL,
} from "pages/constants/base-routes";

import {
	generatePeriodParamsString,
} from "./generate-period-params-string";

interface GenerateTimeJournalPageUrlParams extends Partial<WithPeriodDates> {
	employeeId: EmployeeId;
}

const generateTimeJournalPageUrl = ({
	employeeId,
	periodStartDate,
	periodEndDate,
}: GenerateTimeJournalPageUrlParams): string => {
	const periodParamsString = generatePeriodParamsString({
		periodStartDate,
		periodEndDate,
	});

	const employeeTimeJournalBaseUrl = `${TIME_JOURNAL_BASE_URL}/${employeeId}`;

	if (isEmpty(periodParamsString)) {
		return employeeTimeJournalBaseUrl;
	}

	return `${employeeTimeJournalBaseUrl}/${periodParamsString}`;
};

export {
	generateTimeJournalPageUrl,
};
