/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";

import {
	type PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type WithProjectId,
} from "models/projects/types";
import {
	PROJECTS_BASE_URL,
} from "pages/constants/base-routes";

import {
	generateProjectPageParamsString,
} from "./generate-project-page-params-string";

interface GenerateProjectPageUrlParams extends
	WithProjectId,
	Partial<WithPeriodDates> {
	viewMode?:
		| PROJECT_VIEW_MODE
		| `:viewMode(${PROJECT_VIEW_MODE})`;
}

const generateProjectPageUrl = ({
	projectId,
	periodStartDate,
	periodEndDate,
	viewMode,
}: GenerateProjectPageUrlParams): string => {
	const projectBaseUrl = `${PROJECTS_BASE_URL}/${projectId}`;

	if (
		isUndefined(periodStartDate)
		|| isUndefined(periodEndDate)
	) {
		return projectBaseUrl;
	}

	const pageParamsString = generateProjectPageParamsString({
		periodStartDate,
		periodEndDate,
		viewMode,
	});

	return `${projectBaseUrl}/${pageParamsString}`;
};

export {
	generateProjectPageUrl,
};
